import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Skill Practice: Rope Climbs`}</p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`3-Rope Climbs to 15′ (RX+ = legless)`}</p>
    <p>{`20-Wall Balls (20/14) (RX+ 30/20)`}</p>
    <p>{`rest, then:`}</p>
    <p>{`2 Rounds for time:`}</p>
    <p>{`140ft Sled Pull (sled + 135/90`}{`#`}{`)`}</p>
    <p>{`480M Sandbag Run (70/40`}{`#`}{` sandbag)`}</p>
    <p><em parentName="p">{`*`}{`Half the class will start on the first wod inside, half will start on
the second wod outside.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      